body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  width: 100%;
  font-family: Roboto Slab;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mainAppDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100dvw;
  margin: auto;
  position: relative;
}

.routesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1400px;
  margin: auto;
  min-height: 100dvh;
}

.navDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 100;
  padding: 0 20px;
  box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.navSubDiv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.navWidth {
  width: 1400px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navMisc {
  display: flex;
  flex-direction: row;
  height: 40px;
  margin-right: 30px;
}

.navDropDown {
  position: relative;
  display: flex;
  z-index: 110;
}

.navDropDownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9ef;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  left: -100px;
  flex-direction: column;
  text-decoration: none;
  border-radius: 12px;
}
.navDropDownContentNoSearch {
  display: none;
  position: absolute;
  background-color: #f9f9f9ef;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  top: 30px;
  left: -220px;
  flex-direction: column;
  text-decoration: none;
  border-radius: 12px;
}

.navDropDown:hover .navDropDownContent,
.navDropDown:hover .navDropDownContentNoSearch {
  display: flex;
}
.navDropDownContent:hover .pulsatingLink,
.navDropDownContentNoSearch:hover .pulsatingLink {
  animation: none;
}
.navDropDownCard {
  display: flex;
  width: 240px;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  background-color: #2a2e45ed;
}

@media only screen and (max-width: 700px) {
  .navMisc {
    margin-right: 30px;
  }
}
@media only screen and (max-width: 1400px) {
  .navMisc {
    margin-right: 30px;
  }
  .routesContainer {
    width: 100%;
  }
}
