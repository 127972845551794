.register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40vh;
  background-color: #f5f5f5;
  flex-direction: column;
  padding: 5px;
  min-width: 80vw;
}
.formClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 20vh;
  background-color: #f5f5f5;
  flex-direction: column;
  padding: 5px;
  min-width: 60vw;
}
.formClassDiv {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20vh;
  background-color: #f5f5f5;
  padding: 5px;
  min-width: 40vw;
}

.jobs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  min-height: 100vh;
}

.imgArrows {
  height: 130px;
  width: 220px;
  position: absolute;
  top: -20.2px;
  left: 28.7vw;
  z-index: 1;
  filter: sepia(80);
  background: #ff00009b;
}

/* .jobd:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 36%;
  height: 100%;
  width: 120px;
  background-color: green;
}
.jobd:after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 45%;
  height: 100%;
  width: 120px;
  background-color: red;
} */
.linksRouteDiv {
  display: flex;

  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.linkdProfile {
  display: flex;
  flex-direction: column;
  width: 94%;
  align-items: center;
  justify-content: center;
  margin-top: 4dvh;
}

.linkdLinkd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10dvh;
  margin-bottom: 200px;
}

.profileMainDiv {
  display: flex;
  flex-direction: column;
  width: 90%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  background-color: #f5f5f5;
  padding: 8px;
}

.editProfileMainDiv {
  display: flex;
  flex-direction: column;
  width: 90%;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 60px;
  height: 100dvh;
}
.profileCard {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  text-align: left;

  width: 90%;
  justify-content: center;
  position: relative;
}
.profileContentDiv {
  width: 100%;
}
input[type="file"] {
  display: none;
}
.uploadCv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 38px;
  cursor: pointer;
  background-color: #2a2e45;
  color: white;
  border-radius: 20px;
  font-weight: 500;
  margin: 10px 0;
}

.uploadCv:hover {
  background-color: grey;
  color: #2a2e45;
}

.resumeButton a {
  text-decoration: none;
  color: white;
  font-weight: 800;
}
.resumeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.resumeRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  word-break: break-word;
  background-color: #2a2e45;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.resumeRow:hover {
  background-color: grey;
  color: #2a2e45;
}
.resumeRow:hover a {
  background-color: grey;
  color: #2a2e45;
}

Button:disabled {
  background-color: white;
}

.deleteResume {
  margin: 0px;
  margin-right: 20px;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  color: white;
}
.deleteResume:hover {
  color: red;
}

/* Avatarrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr */
.profileAvatar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profileAvatarCard {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: top;
  color: #2a2e45;
  border-radius: 16px;
  padding: 4px;
  margin-top: 0px;
  width: 70%;
}
.commentAvatar {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  width: 90%;
  margin-top: 0px;
  word-break: break-word;
}
.profileButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  flex-direction: column;
}

.rowDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  word-break: break-word;
}

.rowDivProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.jobsRouteDiv {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.jobdJobd {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10dvh;
  flex-wrap: wrap;
  margin-bottom: 20dvh;
  min-height: 100dvh;
}

.addButton {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 5px 2px 5px 2px rgb(68, 68, 68);
  color: black;
  height: 100px;
  width: 340px;
  border-radius: 12px;
  font-size: large;
  font-weight: 900;
}

.addButton:hover {
  background-color: #2a2e45;
  color: white;
}

.loginMVPimage {
  animation: loginMVP 2s infinite;
}

.user {
  color: grey;
}

@keyframes loginMVP {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
.linkedinLoginButton:hover .linkedinLoginIcon {
  color: #1466c2;
}

/* LinkView..................................................................... */
.linkView {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 120dvh;
  margin-bottom: 16dvh;
}
.linkViewSections {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46dvh;
  width: 60%;
  margin-top: 8dvh;
}
.linkViewCard {
  width: 360px;
  height: 340px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 16px;
  padding: 16px;
}
.shareModal {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;

  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 1000;
  height: 80dvh;
  width: 50dvw;
  border-radius: 10px;
  background-color: white;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.linkViewShareCard {
  width: 360px;
  height: 340px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 20px;
}
.linkViewShareImage {
  opacity: 1;
  width: 100%;
}
.shareButtons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  margin: auto;
}
.shareButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 160px;
  margin: auto;
  padding: 8px;
  border-radius: 20px;
  color: rgb(255, 255, 255);
  margin: 8px;
  background-color: #2a2e45;
  height: 40px;
  border: 0;
  font-weight: 900;
}
.shareButton:hover {
  background-color: white;
  color: #2a2e45;
}
.shareButton:hover .shareSpan {
  color: #1466c2;
}
.captionInput {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  margin: auto;
  height: 100px;
  border-radius: 10px;
  padding: 10px;
}
.shareTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 80%;
  margin: auto;
  margin-top: 60px;
}
.shareSpan {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
.shareHeaderSpan {
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1466c2;
}
.linkViewCardDetails {
  width: 360px;
  height: 340px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin: 16px;
}
.linkViewCardHeader {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  height: 90px;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  position: relative;
}

.postJobHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 90px;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  position: relative;
  background-color: #2a2e45;
  color: white;
  border-radius: 12px;
  margin-bottom: 10px;
}

.linkViewCardContent {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 180px;
  overflow: auto;
  word-break: break-word;
  margin-top: 10px;
}
.cardActions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 92%;
}

.cardHeaderTitle {
  font-size: 24px;
  font-weight: bold;
  color: black;
  margin: 0;
  display: flex;
  flex-direction: row;
  word-break: break-all;
}
.cardHeaderSubTitle {
  font-size: 16px;
  color: black;
  margin: 0;
  word-break: break-all;
}
.avatarDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  width: 100%;
  cursor: pointer;
}
.avatarTitle {
  font-size: 16px;
  color: black;
  font-weight: bold;
  word-break: break-word;
}

.profileAvatarDiv {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;

  width: 100%;
  margin-left: 20px;
}
.profileAvatarTitle {
  color: grey;
  word-break: break-word;
  margin: 0;
}

.profileLinks {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-top: 8px;
}
.profileAvatarLocation {
  font-size: 14px;
  color: black;
  word-break: break-word;
  margin: 0;
  display: flex;
  align-items: center;
}

.profileLinkIcon {
  font-size: 16px;
  color: black;
  margin: 0;
  display: flex;
  align-items: center;
  background-color: #2a2e45;
  color: white;
  padding: 4px;
  border-radius: 6px;
}
.profileLinkIcon:hover {
  font-size: 16px;
  color: black;
  background-color: white;
  margin: 0;
  display: flex;
  align-items: center;
}
.headerNormalText {
  font-size: 16px;
  color: black;
  word-break: break-all;
}
.headerGreyText {
  font-size: 16px;
  color: grey;
  white-space: pre-wrap;
}
.centreDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.navMisc {
  color: black;
}

.footDiv {
  height: 100px;
  position: absolute;
  background-color: white;
  width: 100dvw;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  box-shadow: 0 -2px 2px 2px rgba(0, 0, 0, 0.1);
}
.footTitle {
  width: 1400px;
  flex-direction: row;
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footTitleDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  font-size: 12px;
  color: black;
  padding: 0;
}

.footImage {
  height: 48px;
  z-index: 5;
}
.imageFooterDiv {
  width: 100px;
}
.footDiv:hover .footImage {
  animation: footimation 2s 1;
}

.editPage {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
  margin: auto;
}

.editpageForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;
  min-height: 100vh;
}
@keyframes footimation {
  0% {
    transform: translateX(50px) scale(1.2);
  }

  50% {
    transform: translateX(150px) scale(1.4);
  }

  100% {
    transform: translateX(300px) scale(1.6);
  }
}
@keyframes footimationPhone {
  0% {
    transform: translateX(50px) scale(1.2);
  }

  50% {
    transform: translateX(100px) scale(1.4);
  }

  100% {
    transform: translateX(180px) scale(1.6);
  }
}
.footTitleText {
  font-size: 12px;
  color: black;
  margin: 0;
  padding: 0;
  margin-bottom: 8px;
  width: 200px;
}
.footRightSide {
  font-size: 12px;
  color: black;
  width: 300px;
  padding: 0;
}
.footRightSideText {
  font-size: 12px;
  color: grey;
}
@media only screen and (max-width: 1400px) {
  .jobdLinkdAddJob {
    width: 40%;
  }
}

@media only screen and (max-width: 1000px) {
  .profileMainDiv {
    position: relative;
    width: 96%;
    margin-bottom: 8dvh;
    padding: 8px;
  }
  .profileCard {
    flex-direction: column;
    width: 100%;
    padding: 40px;
  }
  .profileContentDiv {
    width: 100%;
  }
  .profileCentreText {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .jobsRouteDiv {
    flex-direction: column;
  }

  .jobdJobd {
    width: 100dvw;
    flex-direction: column;
    margin-left: 0;
  }
  .jobdLinkdAddJob {
    width: 100dvw;
    min-height: 40dvh;
    margin-left: 0;
    position: relative;
    left: auto;
  }
  .linksRouteDiv {
    flex-direction: column;
  }
  .linkdLinkd {
    width: 100dvw;
    flex-direction: column;
  }
  .addButton {
    margin-left: 0;
    position: relative;

    left: 0;
    top: 0;
  }
  .linkViewSections {
    width: 100dvw;
  }
  .rowDivProfile {
    flex-direction: column;
  }
  .profileMainDiv {
    padding: 0;

    min-height: 50dvh;
  }
  .profileCard {
    padding: 0;
    min-height: 50dvh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .profileAvatar {
    flex-direction: column;
    padding: 0;
    margin-left: 80px;
  }
  .profileAvatarCard {
    padding: 0;
    width: 100%;
  }
  .profileButton {
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    flex-direction: row;
    justify-content: space-between;
  }
  .footTitleDiv {
    width: 46dvw;
  }
  .footTitle {
    width: 100dvw;
  }
  .footRightSide {
    font-size: 8px;
    width: 30dvw;
  }
  .shareModal {
    width: 96dvw;
    height: 84dvh;
    top: 44%;
  }
  .shareTitle {
    margin-top: 20px;
  }
  .captionInput {
    min-height: 60px;
    width: 80%;
  }
  .footDiv:hover .footImage {
    animation: footimationPhone 2s 1;
  }
  .uploadCv {
    width: 120px;
  }
}
